<!--导入 excel-->
<template>
  <div class="ss-import-excel">
    <a-upload v-if="direct"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :disabled="disabled" :beforeUpload="beforeUpload">
      <a-button :disabled="disabled" :type="type">{{ text }}</a-button>
    </a-upload>

    <a-button v-else :disabled="disabled" :type="type" @click="showModal">{{ text }}</a-button>

    <a-modal :title="tplTitle"
             :visible="modalShown"
             :confirm-loading="confirmLoading"
             :mask-closable="true"
             :destroy-on-close="true"
             dialog-class="import-excel-modal"
             @ok="confirmModal"
             @cancel="closeModal">
      <a-form>
        <a-form-item label="导入文件">
          <a-input type="file" @change="fileChange"></a-input>
        </a-form-item>
        <div class="msg">
          <span>注: 文件类型必须为 xlsx，如果没有模板-></span>
          <a-button type="link" @click="downloadTpl">点击下载</a-button>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import XLSX from 'xlsx';

import {getTemplateUrl, saveFileScript, saveFileSynonym, saveFileIllegalWord, saveFileKeyword} from '@/api/ImportExcel';
import Common from '@/utils/Common';


const importTypeEnum = {
  '1': 'skill',
  '2': 'synonym',
  '3': 'badword',
  '4': 'keyword',
};

export default {
  name: 'ImportExcel',
  props: {
    type: {type: String, default: ''}, // 组件 UI 中按钮的类型，同 AButton 类型相同
    text: {type: String, default: '导入 Excel'},
    disabled: {type: Boolean, default: false},
    direct: {type: Boolean, default: false}, // 是否直接读取并返回文件内容
    importType: {
      type: Number, default: 0, validate: (type) => {
        return !!importTypeEnum[type + ''];
      },
    },
  },
  data() {
    return {
      loading: false,
      modalShown: false,
      confirmLoading: false,

      tplTitle: '',
      tplUrl: '',
      templateUrls: {},

      file: null,
    }
  },
  created() {
    this.getTemplate();
  },
  methods: {

    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        this.readExcel(file).then((data) => reject(data)).catch((res) => reject(res));
      });
    },

    // 读取 excel 文件内容
    readExcel(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          try {
            const excel = XLSX.read(event.target.result, {type: 'binary'});
            const sheetName = excel.SheetNames[0];
            const data = XLSX.utils.sheet_to_json(excel.Sheets[sheetName]);

            this.sendData(data);
            resolve('Success');
          } catch (err) {
            reject('Fail');
          }
        }
        reader.readAsBinaryString(file);
      });
    },

    sendData(data) {
      if (!data || !data.length) {
        return;
      }
      this.$emit('finish', {data});
    },

    // 获取模板名称及模板地址
    async getTemplate() {
      this.loading = true;
      const data = await getTemplateUrl().finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }

      this.templateUrls = data?.data || {};

      const type = importTypeEnum[this.importType] || '';
      const tpl = this.templateUrls[type] || {};
      this.tplTitle = tpl.title || '';
      this.tplUrl = tpl.url || '';
    },

    // 下载模板
    downloadTpl() {
      Common.downloadFile(this.tplUrl, this.tplTitle);
    },

    fileChange(event) {
      this.file = event?.target?.files[0] || null;
    },

    // 点击“确定”按钮保存文件
    saveFile() {
      if (!this.file) {
        return this.$message.info({content: '请选择文件'});
      }
      switch (this.importType) {
        case 1:
          this.saveFileScript();
          break;
        case 2:
          this.saveFileSynonym();
          break;
        case 3:
          this.saveFileIllegalWord();
          break;
        case 4:
          this.saveFileKeyword();
      }
    },

    // 转换选中的文件为 formData 格式，用于上传给后台
    getFileFormDate() {
      const formData = new FormData();
      formData.append('file', this.file);
      return formData;
    },

    // 导入文件返回错误，显示的模板
    uploadFileErrorPrompt(error, msg) {
      error = error || [];
      msg = msg || '上传文件中存在错误';
      this.$notice({
        title: msg,
        content: h => h('div', {}, [
          h('p'),
          ...error.map(err => h('div', {}, [
            (err.serial || err.serial === 0) ? h('h3', `序号 ${err.serial}`) : null,
            ...err.error.map(i => h('p', i)),
          ])),
        ]),
      });
    },

    // 导入话术
    async saveFileScript() {
      const data = await saveFileScript(this.getFileFormDate());
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          this.uploadFileErrorPrompt(data?.data?.error, data?.data?.message);
          return;
        }
        return this.$message.error({content: data?.message || '上传失败'});
      }
      this.$message.success({content: data.message || '上传成功'});
      this.closeModal();
      this.$emit('success');
    },
    // 导入同义词
    async saveFileSynonym() {
      const data = await saveFileSynonym(this.getFileFormDate());
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          this.uploadFileErrorPrompt(data?.data?.error, data?.data?.message);
          return;
        }
        return this.$message.error({content: data?.message || '上传失败'});
      }
      this.$message.success({content: data.message || '上传成功'});
      this.closeModal();
      this.$emit('success');
    },
    // 导入违禁词
    async saveFileIllegalWord() {
      const data = await saveFileIllegalWord(this.getFileFormDate());
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          this.uploadFileErrorPrompt(data?.data?.error, data?.data?.message);
          return;
        }
        return this.$message.error({content: data?.message || '上传失败'});
      }
      this.$message.success({content: data.message || '上传成功'});
      this.closeModal();
      this.$emit('success');
    },
    // 导入关键词
    async saveFileKeyword() {
      const data = await saveFileKeyword(this.getFileFormDate());
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          this.uploadFileErrorPrompt(data?.data?.error, data?.data?.message);
          return;
        }
        return this.$message.error({content: data?.message || '上传失败'});
      }
      this.$message.success({content: data.message || '上传成功'});
      this.closeModal();
      this.$emit('success');
    },

    showModal() {
      this.modalShown = true;
    },
    confirmModal() {
      this.saveFile();
    },
    closeModal() {
      this.modalShown = false;
      this.file = null;
    },
  },
}
</script>

<style scoped lang="scss">
.ss-import-excel {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.import-excel-modal {
  .msg {
    font-size: 12px;
    color: $light04;
  }

  .link {
    font-size: 14px;
  }

  .ant-input {
    height: 38px;
  }
}
</style>
