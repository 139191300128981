<template>
  <a-modal title="批量修改信息"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :destroy-on-close="true"
           dialog-class="import-excel-modal"
           :after-close="reset"
           @ok="confirm"
           @cancel="close">
    <a-form :form="form"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }">
      <a-form-item label="配图">
        <UploadImage v-decorator="['img_urls', decorator.img_urls]"/>
        <div> 配图不修改时，不要上传图片</div>
      </a-form-item>

      <a-form-item label="岗位">
        <app-select-job v-decorator="['job_ids', decorator.job_ids]"
                        placeholder="不修改"/>
        <app-select-base placeholder="若使用其他话术设置好的岗位，请选择话术"
                         mode="default"
                         :list="skillList"
                         :options="{id: 'id', name: 'title'}"
                         @change="setField('job_ids', $event)"/>
      </a-form-item>

      <a-form-item label="组织机构">
        <app-select-org v-decorator="['org_ids', decorator.org_ids]"
                        placeholder="不修改"
                        class="select-org"/>
        <app-select-base placeholder="若使用其他话术设置好的岗位，请选择话术"
                         mode="default"
                         :list="skillList"
                         :options="{id: 'id', name: 'title'}"
                         @change="setField('org_ids',$event)"/>
      </a-form-item>

      <a-form-item label="答题时长">
        <a-input-number v-decorator="['answer_limit_time', decorator.answer_limit_time]"
                        :min="5"
                        placeholder="不修改"
                        style="width: 100px;"/>
        <span class="ml-5">秒，答题限时必须大于 5 秒</span>
        <div>答题时长不修改时，框里不要填写内容</div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  getPostOrgBySkill,
  batchSaveSkillBase,
} from '../api';

import UploadImage from './UploadImage.vue';

const decorator = {
  img_urls: {
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: false, message: '请上传图片' }],
  },
  job_ids: {
    rules: [{ required: false, message: '请选择岗位' }],
  },
  org_ids: {
    rules: [{ required: false, message: '请选择组织机构' }],
  },
  answer_limit_time: {
    rules: [{ required: false, message: '请输入答题时长' }],
  },
};

export default {
  name: 'EditSkillModal',
  components: {
    UploadImage,
  },
  data() {
    return {
      loading: false,
      uploading: false, // 是否正在上传图片
      shown: false,

      ids: '',

      skillList: [],

      decorator,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    show({ ids }) {
      this.ids = ids || '';

      this.getPostOrgBySkill();
      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.ids = '';
    },

    setField(field, id) {
      const fields = {
        job_ids: 'post_ids_str',
        org_ids: 'org_ids_str',
      };
      this.form.setFieldsValue({
        [field]: this.skillList.filter(
          (item) => item.id === +id,
        )?.[0]?.[fields[field]] || undefined,
      });
    },

    confirm() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = { ids_str: this.ids };

          ['job_ids', 'org_ids', 'answer_limit_time'].forEach((key) => {
            if (values[key]) {
              params[key] = values[key];
            }
          });
          if (values.img_urls?.length) {
            params.img_urls = JSON.stringify(
              values.img_urls.map((item) => item.response?.data?.url || ''),
            );
          }

          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await batchSaveSkillBase(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data?.message || '保存成功');
      this.$emit('success');
      this.close();
    },

    async getPostOrgBySkill() {
      this.loading = true;
      const data = await getPostOrgBySkill().finally(() => {
        this.loading = false;
      });
      this.skillList = data?.data || [];
    },
  },
};
</script>

<style scoped lang="scss">
.select-org ::v-deep .select {
  height: auto;
}
</style>
