<template>
  <router-view/>
</template>

<script>
export default {
  name: 'Speechcraft',
  created() {
    this.$store.dispatch('Common/getScriptStatus');
    this.$store.dispatch('Common/getScriptCategories');
  },
};
</script>
