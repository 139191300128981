import request from '../utils/Request';

// 话术-获得批量导入模板地址
export function getTemplateUrl() {
  return request({
    url: '/speechskill/admin/skill/import-tmpl',
    method: 'get'
  });
}

// 话术-批量导入操作
export function saveFileScript(data) {
  return request({
    url: '/speechskill/admin/skill/import',
    method: 'post',
    data
  });
}

// 话术同义词-批量导入操作
export function saveFileSynonym(data) {
  return request({
    url: '/speechskill/admin/synonym/import',
    method: 'post',
    data
  });
}

// 话术违规词-批量导入操作
export function saveFileIllegalWord(data) {
  return request({
    url: '/speechskill/admin/badword/import',
    method: 'post',
    data
  });
}

// 话术违规词-批量导入操作
export function saveFileKeyword(data) {
  return request({
    url: '/speechskill/admin/keyword/import',
    method: 'post',
    data
  });
}

// 权限配置-获得批量导入模板地址
export function getTemplateLimitUrl() {
  return request({
    url: '/public/conf/offline/getImportTemplate',
    method: 'get'
  });
}

// 权限配置-批量导入操作
export function saveFileLimitScript(data) {
  return request({
    url: '/manage/admin/regional-manager/import',
    method: 'post',
    data
  });
}