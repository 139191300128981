<template>
  <div class="ss-script-category">
    <div class="right-top-btn-group">
      <a-button @click="showCreateCategoryModal">创建分类</a-button>
      <a-button @click="showCreateNameModal">创建名称</a-button>
    </div>

    <div class="pd-card">
      <a-collapse v-if="categories && categories.length" v-model="activeKey">
        <a-collapse-panel v-for="item of categories" :key="item.id.toString()" :header="item.title">
          <div slot="extra" class="panel-extra">
            <div class="btn-wrapper">
              <a-button type="link" @click.stop="editLevel1(item)">编辑</a-button>
              <a-button type="link" @click.stop="deleteLevel1(item)">删除</a-button>
            </div>
            <span>话术数量：{{ item.speech_skill_num || 0 }}</span>
          </div>

          <template v-if="item.children && item.children.length">
            <a-table :columns="columns" :data-source="item.children" :pagination="false" :loading="loading"
                     row-key="id" class="mb-10 table small-cell-table">
              <template slot="action" slot-scope="text, record">
                <a-button type="link" @click="editLevel2(record)">编辑</a-button>
                <a-button type="link" @click="deleteLevel2(record)">删除</a-button>
              </template>
            </a-table>
          </template>
          <template v-else>
            <div class="row row-center">暂无数据</div>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <a-modal :title="'请输入' + modalTitle"
             :visible="createModalShown"
             :confirm-loading="confirmLoading"
             :after-close="afterModalClosed"
             @ok="confirmModal"
             @cancel="closeModal">
      <a-form>
        <a-form-item v-if="modalType === 2 && (!editedItem || !editedItem.id)" label="选择话术分类">
          <a-select v-model="selectedCategoryId" placeholder="选择话术分类">
            <a-select-option v-for="item of categories" :key="item.id" :value="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="modalTitle">
          <a-input v-model="modalInputTxt" :placeholder="modalTitle"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  saveLevel1,
  saveLevel2,
  deleteCategory,
} from './api/category';

export default {
  name: 'Category',
  computed: {
    ...mapState({
      categories: state => state.Common.scriptCategoryList,
    }),
    modalTitle() {
      switch (this.modalType) {
        case 1:
          return '话术类别';
        case 2:
          return '话术名称';
      }
      return '';
    },
  },
  data() {
    return {
      columns: [
        { align: 'center', dataIndex: 'id', key: 'id', title: 'ID' },
        { align: 'center', dataIndex: 'title', key: 'title', title: '名称' },
        { align: 'center', dataIndex: 'speech_skill_num', key: 'speech_skill_num', title: '话术数量' },
        {
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          width: '180px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      activeKey: [], // 展开的面板 key
      loading: false,

      modalType: 0, // 1: 话术；2：名称；
      selectedCategoryId: undefined,
      createModalShown: false, // 新建话术分类弹窗，新建话术二级分类弹窗
      confirmLoading: false, // 两个弹窗公用
      modalInputTxt: '',
      editedItem: {},
    };
  },
  created() {
    this.getScriptCategories();
  },
  methods: {
    // 获取话术分类列表
    async getScriptCategories() {
      this.$store.dispatch('Common/getScriptCategories');
      this.activeAll();
    },
    // 展开所有折叠面板
    activeAll() {
      if (!this.categories || !this.categories.length) {
        return this.activeKey = [];
      }
      this.activeKey = this.categories.map(i => i.id.toString());
    },

    showCreateCategoryModal() {
      this.modalType = 1;
      this.selectedCategoryId = '';
      this.createModalShown = true;
    },
    showCreateNameModal() {
      this.modalType = 2;
      this.createModalShown = true;
    },
    closeModal() {
      this.createModalShown = false;
    },
    afterModalClosed() {
      this.editedItem = null;
      this.selectedCategoryId = undefined;
      this.modalInputTxt = '';
    },
    confirmModal() {
      switch (this.modalType) {
        case 1:
          this.saveLevel1().then(() => this.closeModal());
          break;
        case 2:
          this.saveLevel2().then(() => this.closeModal());
          break;
      }
    },

    async saveLevel1() {
      if (this.confirmLoading) {
        return Promise.reject('Combo');
      }
      this.confirmLoading = true;
      const id = this.editedItem?.id || null;
      const title = this.modalInputTxt || null;
      if (!title) {
        this.confirmLoading = false;
        this.$message.warning({ content: '请输入话术分类' });
        return Promise.reject('EmptyTitle');
      }
      const data = await saveLevel1({ id, title }).finally(() => this.confirmLoading = false);
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return Promise.reject('SaveFail');
      }
      this.$message.success({ content: data?.message || '保存成功' });

      if (!id) {
        this.getScriptCategories();
        return Promise.resolve();
      }

      const categories = this.categories || [];
      categories.forEach(category => {
        if (category.id === id) {
          category.title = title;
        }
      });
      this.$store.commit('Common/updateScriptCategoryList', categories);

      return Promise.resolve();
    },
    async saveLevel2() {
      if (this.confirmLoading) {
        return Promise.reject('Combo');
      }
      this.confirmLoading = true;
      const id = this.editedItem?.id || null;
      const pid = this.selectedCategoryId || null;
      const title = this.modalInputTxt || null;
      if (!pid) {
        this.confirmLoading = false;
        this.$message.warning({ content: '请选择话术分类' });
        return Promise.reject('EmptyPid');
      }
      if (!title) {
        this.confirmLoading = false;
        this.$message.warning({ content: '请输入话术名称' });
        return Promise.reject('EmptyTitle');
      }
      const data = await saveLevel2({ id, pid, title }).finally(() => this.confirmLoading = false);
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return Promise.reject('SaveFail');
      }
      this.$message.success({ content: data?.message || '保存成功' });

      if (!id) {
        this.getScriptCategories();
        return Promise.resolve();
      }

      const categories = this.categories || [];
      categories.forEach(category => {
        if (category.id === pid && category.children && category.children.length) {
          category.children.some(i => {
            if (i.id === id) {
              i.title = title;
            }
          });
        }
      });
      this.$store.commit('Common/updateScriptCategoryList', categories);

      return Promise.resolve();
    },

    /**
     * 编辑一级分类
     * @param item
     */
    editLevel1(item) {
      this.editedItem = item;
      this.modalInputTxt = item.title;
      this.showCreateCategoryModal();
    },
    /**
     * 编辑二级分类
     * @param item
     */
    editLevel2(item) {
      this.editedItem = item;
      this.selectedCategoryId = item.pid;
      this.modalInputTxt = item.title;
      this.showCreateNameModal();
    },

    /**
     * 删除一级分类
     * @param item
     */
    deleteLevel1(item) {
      this.$confirm({
        content: () => <div>确认删除 {item.title}？</div>,
        onOk: () => {
          this.confirmDeleteLevel1(item);
        },
      });
    },

    async confirmDeleteLevel1(item) {
      const data = await deleteCategory({ id: item.id });
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '删除失败' });
      }
      this.$message.success({ content: data?.message || '删除成功' });
      const categories = this.categories || [];
      const idx = categories.findIndex(i => i.id === item.id);
      if (idx > -1) {
        categories.splice(idx, 1);
      }
      this.$store.commit('Common/updateScriptCategoryList', categories);
    },

    /**
     * 删除二级分类
     * @param item
     */
    deleteLevel2(item) {
      this.$confirm({
        content: () => <div>确认删除 {item.title}？</div>,
        onOk: () => {
          this.confirmDeleteLevel2(item);
        },
      });
    },

    async confirmDeleteLevel2(item) {
      const data = await deleteCategory({ id: item.id });
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '删除失败' });
      }
      this.$message.success({ content: data?.message || '删除成功' });
      const categories = this.categories || [];
      categories.forEach(category => {
        if (category.id === item.pid && category.children && category.children.length) {
          category.children = category.children.filter(i => i.id !== item.id);
        }
      });
      this.$store.commit('Common/updateScriptCategoryList', categories);
    },

  },
};
</script>

<style scoped lang="scss">
.ss-script-category {
  .panel-extra {
    position: relative;
  }

  .btn-wrapper {
    position: absolute;
    top: 50%;
    right: calc(100% + 10px);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
}
</style>
