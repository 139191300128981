import request from '@/utils/Request';

// 话术-违规词列表
export function getBadWords(params) {
  return request({
    url: '/speechskill/admin/badword/index',
    method: 'get',
    params,
  });
}

// 话术-违规词保存
export function saveBadWord(data) {
  return request({
    url: '/speechskill/admin/badword/save',
    method: 'post',
    data,
  });
}

// 话术-违规词删除
export function deleteBadWord(data) {
  return request({
    url: '/speechskill/admin/badword/delete',
    method: 'post',
    data,
  });
}
