<template>
  <div class="ss-assessment-pattern">
    <div class="row wrapper">
      <div class="col col-column left">
        <div v-if="studySwitch === 'on'" class="row">
          <div class="col">
            <span class="title"><span class="red">*</span>学习次数：</span>
            <a-input-number v-model="studyNum" :disabled="readOnly" :min="0" placeholder="学习次数"
                            class="input-number"></a-input-number>
            <span class="extra">次</span>
          </div>
          <div class="input-hint">填0表示不限制</div>
        </div>

        <div v-if="studySwitch === 'on'" class="row">
          <div class="col">
            <span class="title"><span class="red">*</span>学习合格分：</span>
            <a-input-number v-model="studyPassingScore" :disabled="readOnly" :min="0" :max="100" placeholder="学习合格分"
                            class="input-number"></a-input-number>
            <span class="extra">分</span>
          </div>
          <div class="input-hint">填0表示不限制</div>
        </div>

        <div class="row">
          <div class="col">
            <span class="title"><span class="red">*</span>练习次数：</span>
            <a-input-number v-model="practiceNum" :disabled="readOnly" :min="0" placeholder="练习次数"
                            class="input-number"></a-input-number>
            <span class="extra">次</span>
          </div>
          <div class="input-hint">填0表示不限制</div>
        </div>

        <div class="row">
          <div class="col">
            <span class="title"><span class="red">*</span>练习合格分：</span>
            <a-input-number v-model="practicePassingScore" :disabled="readOnly" :min="0" :max="100" placeholder="练习合格分"
                            class="input-number"></a-input-number>
            <span class="extra">分</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="title"><span class="red">*</span>检核题目数：</span>
            <a-input-number v-model="questionNum" :disabled="readOnly" :min="0" placeholder="检核题目数"
                            class="input-number"></a-input-number>
            <span class="extra">题</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="title"><span class="red">*</span>检核次数：</span>
            <a-input-number v-model="examNum" :disabled="readOnly" :min="0" placeholder="检核次数"
                            class="input-number"></a-input-number>
          </div>
          <div class="input-hint">填0表示不限制</div>
        </div>

        <div class="row">
          <div class="col">
            <span><span class="red">*</span>检核合格分数：</span>
            <a-input-number v-model="examPassingScore" :disabled="readOnly" :min="1" :max="100" placeholder="检核合格分数"
                            class="input-number"></a-input-number>
          </div>
        </div>

      </div>

      <div class="col col-column right">

        <div class="row keyword-score-rate">
          <div class="col">
            <span class="title"><span class="red">*</span>评分计算公式：</span>
            <div class="row row-column">
              <div class="col">
                <span class="prefix">关键词得分占比</span>
                <a-input-number v-model="keywordPercentage" :disabled="readOnly" :min="0"
                                class="short-input input-number"></a-input-number>
                <span>%</span>
                <span class="hint">请填写0至100之间的整数</span>
              </div>
              <div class="col">
                <span class="prefix">全文单字得分占比</span>
                <a-input-number disabled v-model="otherPercentage" :min="0"
                                class="short-input input-number"></a-input-number>
                <span>%</span>
              </div>
            </div>
            <div class="input-hint">比例和需为100%</div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="title"><span class="red">*</span>检核时长：</span>
            <a-input-number v-model="examTime" :disabled="readOnly" :min="0" placeholder="检核时长" class="input-number"
                            style="width: 100px;"/>
            <span class="ml-5" style="flex: auto;">秒</span>
          </div>
        </div>

        <div class="row row-left">
          <div class="col col-left">
            <span class="title"><span class="red">*</span>检核开始时间：</span>
            <a-date-picker show-time v-model="startTimeDefault" :disabled="(readOnly || disableEditExamTime) && assessment.makeup_exam_etime !== 0"
                           @change="startChange"></a-date-picker>
          </div>
        </div>

        <div class="row row-left">
          <div class="col col-left">
            <span class="title"><span class="red">*</span>检核结束时间：</span>
            <a-date-picker show-time v-model="endTimeDefault" :disabled="(readOnly || disableEditExamTime) && assessment.makeup_exam_etime !== 0"
                           @change="endChange"></a-date-picker>
          </div>
        </div>

        <div class="row row-left">
          <div class="col col-left textarea-wrapper">
            <span class="title">检核描述：</span>
            <a-textarea v-model="description" class="textarea"
                        :auto-size="{minRows: 3, maxRows: 8}" :disabled="readOnly" placeholder="检核描述"></a-textarea>
          </div>
        </div>

      </div>
    </div>


    <div class="row mt-30">
      <div class="col col-left">
        <a-button :disabled="readOnly" type="primary" @click="saveAndNext">保存并下一步</a-button>
        <a-button :disabled="readOnly" @click="save(false)">保存本页</a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Moment from 'moment';

import Common from '@/utils/Common';
import { saveSkillGroupPattern } from '../api';

export default {
  name: 'PatternSetting',
  computed: {
    ...mapState({
      readOnly: state => state.Assessment.readOnly,
      assessment: state => state.Assessment.assessment,
    }),
    otherPercentage() {
      return this.keywordPercentage > 100 ? 0 : 100 - this.keywordPercentage;
    },
  },
  watch: {
    'assessment.study_switch'(state) {
      this.studySwitch = state || 'off';
    },
    'assessment.question_num'(num) {
      this.questionNum = num || 0;
    },
    'assessment.description'(desc) {
      this.description = desc || '';
    },
    'assessment.practice_num'(num) {
      this.practiceNum = num || 0;
    },
    'assessment.practice_passing_score'(score) {
      this.practicePassingScore = score || 0;
    },
    'assessment.exam_num'(num) {
      this.examNum = num || 0;
    },
    'assessment.exam_passing_score'(score) {
      this.examPassingScore = score || 1;
    },
    'assessment.exam_time'(timestamp) {
      this.examTime = timestamp;
    },
    'assessment.exam_stime'(timestamp) {
      this.examStartTime = Common.formatDateTime(timestamp * 1000);
      if (timestamp) {
        this.startTimeDefault = Moment(timestamp * 1000);
        if (this.startTimeDefault.isBefore(Moment())) {
          this.disableEditExamTime = true;
        }
      } else {
        this.startTimeDefault = null;
      }
    },
    'assessment.exam_etime'(timestamp) {
      this.examEndTime = Common.formatDateTime(timestamp * 1000);
      if (timestamp) {
        this.endTimeDefault = Moment(timestamp * 1000);
      } else {
        this.endTimeDefault = null;
      }
    },
    'assessment.study_num'(num) {
      this.studyNum = num || 0;
    },
    'assessment.study_passing_score'(score) {
      this.studyPassingScore = score || 0;
    },
    'assessment.keyword_percentage'(per) {
      this.keywordPercentage = per || 0;
    },
  },
  data() {
    return {
      id: null,
      loading: false,

      startTimeDefault: null,
      endTimeDefault: null,

      studySwitch: 'off',

      studyNum: 0,
      studyPassingScore: 0,
      practiceNum: 0,
      practicePassingScore: 0,
      questionNum: 0,
      examNum: 0,
      examPassingScore: 1,
      examTime: 0,
      description: '',
      examStartTime: '',
      examEndTime: '',
      keywordPercentage: 0,

      disableEditExamTime: false,
    };
  },
  created() {
    this.id = this.$route?.query?.id || null;
    this.$store.dispatch('Assessment/getAssessment', this.id);
  },
  destroyed() {
    this.$store.commit('Assessment/updateReadOnly', true);
    this.$store.commit('Assessment/updateAssessmentId', 0);
    this.$store.commit('Assessment/updateAssessment', {});
  },
  methods: {
    saveAndNext() {
      if (this.readOnly) {
        return;
      }
      this.save(() => {
        this.$router.replace({
          path: './verification',
          query: {
            id: this.id,
          },
        });
      });
    },

    async save(next) {
      if (this.readOnly) {
        return;
      }
      if (this.loading) {
        return;
      }

      this.loading = true;
      const data = await saveSkillGroupPattern({
        id: this.id,
        question_num: this.questionNum,
        description: this.description,
        practice_num: this.practiceNum,
        practice_passing_score: this.practicePassingScore,
        exam_num: this.examNum,
        exam_passing_score: this.examPassingScore,
        exam_time: this.examTime,
        exam_stime: this.examStartTime,
        exam_etime: this.examEndTime,
        study_num: this.studyNum,
        study_passing_score: this.studyPassingScore,
        keyword_percentage: this.keywordPercentage,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
      next && next();
    },
    cancel() {
      this.$router.back();
    },

    startChange(date, dateString) {
      this.examStartTime = dateString;
    },
    endChange(date, dateString) {
      this.examEndTime = dateString;
    },
  },
};
</script>

<style scoped lang="scss">
.ss-assessment-pattern {
  width: 100%;

  .row {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .input-number {
    width: 100%;
  }

  .wrapper {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .left {
    margin-right: 50px;

    .col {
      min-width: 240px;
    }
  }

  .left, .right {
    .row {
      position: relative;
    }

    .col {
      height: 50px;
    }
  }

  .input-hint {
    position: absolute;
    left: 105px;
    bottom: 0;
    font-size: 12px;
    color: $light02;
  }

  .keyword-score-rate > .col {
    height: 85px;

    .row-column {
      height: 100%;
      padding-top: 0;

      .col {
        align-items: center;
        padding-left: 0;
      }
    }

    .short-input {
      width: 80px;
      margin: 0 5px;
      padding-left: 2px;
      padding-right: 2px;
    }

    .prefix {
      display: inline-block;
      width: 120px;
      text-align: right;
    }

    .hint {
      margin-left: 5px;
      font-size: 12px;
      color: $light02;
    }
  }

  .textarea-wrapper {
    width: 100%;
    align-items: flex-start;
  }

  .title {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    text-align: right;
  }

  .extra {
    margin-left: 10px;
  }
}
</style>
