<template>
  <div class="ss-assessment-list">
    <div class="right-top-btn-group">
      <a-button @click="batchPullOn">批量上架</a-button>
      <a-button @click="batchPullOff">批量下架</a-button>
      <a-button @click="addGroup">新增检核</a-button>
    </div>

    <div class="row row-left">
      <div class="col col-left mr-30">
        <span>状态：</span>
        <a-select v-model="selectedStatus" style="width: 160px">
          <a-select-option v-for="item of status" :key="item.id" :value="item.id">{{ item.label }}</a-select-option>
        </a-select>
      </div>
      <div class="col">
        <span>名称：</span>
        <a-input-search v-model="filterTxt" placeholder="请输入题干关键词" enter-button="搜 索" @search="onSearch"/>
      </div>
    </div>

    <div class="flex full-width ph-20">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               :scroll="{x: '100%'}"
               :rowSelection="rowSelection"
               bordered
               row-key="id"
               class="table small-cell-table">

        <template slot="skill_ids" slot-scope="text, record">
          <div>{{ record.skill_ids ? record.skill_ids.length : 0 }}</div>
        </template>

        <template slot="post_str_arr" slot-scope="text, record">
          <FullContentViewer title="全部推送岗位"
                             :content="record.post_str_arr"/>
        </template>
        <template slot="org_str_arr" slot-scope="text, record">
          <FullContentViewer title="全部推送机构"
                             :content="record.org_str_arr"/>
        </template>

        <template slot="exam_status" slot-scope="text, record">

          <template v-if="record.exam_stime && record.exam_etime">
            <span v-if="record.exam_stime > nowTimestamp">未开始</span>
            <span v-else-if="record.exam_etime > nowTimestamp">进行中</span>
            <span v-else-if="!record.makeup_exam_stime || !record.makeup_exam_etime">已结束</span>
          </template>

          <template v-if="record.makeup_exam_stime && record.makeup_exam_etime">
            <span v-if="record.makeup_exa_etime > nowTimestamp"></span>
            <span v-else-if="record.makeup_exam_stime > nowTimestamp">补考未开始</span>
            <span v-else-if="record.makeup_exam_etime > nowTimestamp">补考进行中</span>
            <span v-else>补考已结束</span>
          </template>

        </template>
        <template slot="action" slot-scope="text, record">
          <a-button size="small" type="link" v-if="record.status === 1" @click="viewDetail(record)">详情</a-button>
          <a-button size="small" type="link" v-else @click="editItem(record)">编辑</a-button>
          <a-button size="small" type="link" v-if="record.status === 1" @click="pullOff(record)">下架</a-button>
          <a-button size="small" type="link" v-else @click="pullOn(record)">上架</a-button>
          <a-button size="small" type="link" @click="deleteItem(record)">删除</a-button>
          <!--          <a-button size="small" type="link" @click="viewExamScore(record)">考核成绩</a-button>-->
          <a-button size="small" type="link" v-if="!record.makeup_exam_stime || record.makeup_exam_stime > nowTimestamp"
                    @click="showMakeUpModal(record)">
            <span v-if="!record.makeup_exam_stime">开通</span>
            <span v-else>修改</span>
            <span>补考</span>
          </a-button>
        </template>

        <template slot="footer">
          <Pagination :pagination="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <MakeUpModal v-if="makeUpModalShown"
                 :id="selectedItem.id"
                 :exam-end-timestamp="selectedItem.exam_etime"
                 :start-timestamp="selectedItem.makeup_exam_stime"
                 :end-timestamp="selectedItem.makeup_exam_etime"
                 :count="selectedItem.makeup_exam_num"
                 @close="closeMakeUpModal"></MakeUpModal>
  </div>
</template>

<script>
import moment from 'moment';
import table from '@/mixins/table';

import {
  getSkillGroupList,
  pullOnSkillGroup,
  pullOffSkillGroup,
  deleteSkillGroup,
  batchPullOnSkillGroup,
  batchPullOffSkillGroup,
} from './api';

import Common from '@/utils/Common';

import MakeUpModal from '@components/speechSkill/MakeUpModal';
import { statisticsSkillDetail } from '@/utils/Navs/StatisticsNavs';

const columns = [
  {
    width: '100px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '200px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '名称',
    autoExpand: true,
  },
  {
    width: '85px',
    align: 'center',
    dataIndex: 'skill_ids',
    key: 'skill_ids',
    title: '题库数量',
    scopedSlots: { customRender: 'skill_ids' },
  },
  {
    width: '85px',
    align: 'center',
    dataIndex: 'question_num',
    key: 'question_num',
    title: '出题数量',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'exam_time',
    key: 'exam_time',
    title: '考试时长(秒)',
  },
  {
    width: '60px',
    align: 'center',
    dataIndex: 'exam_passing_score',
    key: 'exam_passing_score',
    title: '及格分',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'post_str_arr',
    key: 'post_str_arr',
    title: '推送岗位',
    scopedSlots: { customRender: 'post_str_arr' },
  },
  {
    width: '240px',
    align: 'center',
    dataIndex: 'org_str_arr',
    key: 'org_str_arr',
    title: '推送机构',
    scopedSlots: { customRender: 'org_str_arr' },
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'exam_stime',
    key: 'exam_stime',
    title: '开始时间',
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD HH:mm') : '--',
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'exam_etime',
    key: 'exam_etime',
    title: '结束时间',
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD HH:mm') : '--',
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'makeup_exam_stime',
    key: 'makeup_exam_stime',
    title: '补考开始时间',
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD HH:mm') : '--',
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'makeup_exam_etime',
    key: 'makeup_exam_etime',
    title: '补考结束时间',
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD HH:mm') : '--',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'exam_status',
    key: 'exam_status',
    title: '状态',
    scopedSlots: { customRender: 'exam_status' },
  },
  {
    width: '120px',
    align: 'center',
    title: '是否考核',
    key: 'is_examine',
    dataIndex: 'is_examine',
    customRender: (text) => ['否', '是'][text],
  },
  {
    width: '280px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'List',
  mixins: [table],
  components: { MakeUpModal },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  data() {
    return {
      status: [{ id: 0, label: '全部' }, { id: 1, label: '上架' }, { id: 2, label: '下架' }],
      selectedStatus: 0,
      filterTxt: '',

      data: [], // 话术分类列表
      loading: false,
      originColumns: columns,
      timer: null,
      nowTimestamp: 0,

      selectedItem: 0,
      makeUpModalShown: false,

      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  created() {
    this.getData(1, this.pagination.pageSize);

    this.nowTimestamp = Math.round(new Date().getTime() * 0.001);
    this.timer = setInterval(() => {
      this.nowTimestamp = Math.round(new Date().getTime() * 0.001);
    }, 2000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;

      const data = await getSkillGroupList({
        status: this.selectedStatus ? this.selectedStatus : null,
        keyword: this.filterTxt,
        page: page,
        per_page: pageSize,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    formatTime(time) {
      if (!time) {
        return '';
      }
      return Common.formatTime(new Date(+time * 1000));
    },
    addGroup() {
      this.$router.push({
        path: '/index/speechcraft/group/create/brief',
      });
    },
    editItem(item) {
      this.$router.push({
        path: '/index/speechcraft/group/edit/brief',
        query: {
          id: item.id,
        },
      });
    },
    viewDetail(item) {
      this.$router.push({
        path: '/index/speechcraft/group/detail/brief',
        query: {
          id: item.id,
        },
      });
    },

    pullOn(item) {
      this.$confirm({
        content: () => <div>确认上架？</div>,
        onOk: () => {
          this.confirmPullOn(item);
        },
      });
    },
    async confirmPullOn(item) {
      this.loading = true;
      const data = await pullOnSkillGroup({ id: item.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          const basis = data?.data?.basis || [];
          const required = data?.data?.required || [];
          const question_num = data?.data?.question_num || [];
          this.$notice({
            title: data?.message || '上架失败',
            content: h => h('div', {}, [
              h('p'),
              basis && basis.length ? h('div', {}, [
                h('h3', '基本信息'),
                ...basis.map(i => h('p', i || '')),
              ]) : null,
              required && required.length ? h('div', {}, [
                h('h3', '题目设置'),
                ...required.map(i => h('p', i || '')),
              ]) : null,
              question_num && question_num.length ? h('div', {}, [
                h('h3', '模式设置'),
                ...question_num.map(i => h('p', i || '')),
              ]) : null,
            ]),
          });
          return;
        }
        return this.$message.error({ content: data?.message || '上架失败' });
      }
      this.$message.success({ content: data?.message || '上架成功' });
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    pullOff(item) {
      this.$confirm({
        content: () => <div>确认下架？</div>,
        onOk: () => {
          this.confirmPullOff(item);
        },
      });
    },
    async confirmPullOff(item) {
      this.loading = true;
      const data = await pullOffSkillGroup({ id: item.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          const basis = data?.data?.basis || [];
          const required = data?.data?.required || [];
          const question_num = data?.data?.question_num || [];
          const list = [].concat(...basis, ...required, ...question_num);
          this.$notice({
            title: data?.message || '下架失败',
            content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i || ''))]),
          });
          return;
        }
        return this.$message.error({ content: data?.message || '下架失败' });
      }
      this.$message.success({ content: data?.message || '下架成功' });
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    async viewExamScore(item) {
      const path = statisticsSkillDetail[0].path;
      await this.$router.push(`${path}?id=${item.id}`);
    },

    deleteItem(item) {
      this.$confirm({
        content: () => <div>确认删除话术？</div>,
        onOk: () => {
          this.confirmDelete(item);
        },
      });
    },
    async confirmDelete(item) {
      this.loading = true;
      const data = await deleteSkillGroup({ id: item.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '删除失败' });
      }
      this.$message.success({ content: data?.message || '删除成功' });
      this.data = this.data && this.data.length ? this.data.filter(i => i.id !== item.id) : [];

      const cur = this.pagination.current;
      const page = !this.data?.length ? (cur > 1 ? cur - 1 : 1) : cur;
      this.getData(page, this.pagination.pageSize);
    },

    /**
     * 批量上架
     */
    batchPullOn() {
      if (!this.selectedRowKeys?.length) {
        this.$message.warning({ content: '请选择需要上架的话术' });
        return;
      }
      this.$confirm({
        content: () => <div>确认上架选中的话术？</div>,
        onOk: () => this.confirmBatchPullOn(),
      });
    },
    async confirmBatchPullOn() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      const data = await batchPullOnSkillGroup({
        ids: this.selectedRowKeys?.join(',') || '',
      }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '上架失败' });
        return;
      }
      if (data?.data?.length) {
        const list = this.data?.filter(
          (i) => data?.data?.filter(
            (id) => !!id,
          ).map(
            (id) => +id,
          ).includes(i.id),
        );
        this.$notice({
          title: data?.data?.message || '以下话术检核不能上架',
          content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i.title || ''))]),
        });
      } else {
        this.$message.success({ content: data?.message || '上架成功' });
      }

      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    /**
     * 批量下架
     */
    batchPullOff() {
      if (!this.selectedRowKeys?.length) {
        this.$message.warning({ content: '请选择需要下架的话术' });
        return;
      }
      this.$confirm({
        content: () => <div>确认下架选中的话术？</div>,
        onOk: () => this.confirmBatchPullOff(),
      });
    },
    async confirmBatchPullOff() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      const data = await batchPullOffSkillGroup({
        ids: this.selectedRowKeys?.join(',') || '',
      }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '上架失败' });
        return;
      }
      if (data?.data?.length) {
        const list = this.data?.filter(
          (i) => data?.data?.filter(
            (id) => !!id,
          ).map(
            (id) => +id,
          ).includes(i.id),
        );
        this.$notice({
          title: data?.data?.message || '以下话术检核不能下架',
          content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i.title || ''))]),
        });
      } else {
        this.$message.success({ content: data?.message || '下架成功' });
      }

      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    showMakeUpModal(item) {
      if (item.makeup_exam_stime && item.makeup_exam_stime < this.nowTimestamp) {
        return;
      }
      this.selectedItem = item;
      this.makeUpModalShown = true;
    },
    closeMakeUpModal(event) {
      const id = event?.id || 0;

      const list = this.data || [];
      list.forEach(item => {
        if (item.id === id) {
          item.makeup_exam_stime = Common.transferIosTimeString(event.makeup_exam_stime, 2) || 0;
          item.makeup_exam_etime = Common.transferIosTimeString(event.makeup_exam_etime, 2) || 0;
          item.makeup_exam_num = Common.transferIosTimeString(event.makeup_exam_num, 2) || 0;
        }
      });
      this.data = list;
      this.makeUpModalShown = false;
    },
  },
};
</script>
