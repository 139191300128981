import request from '@/utils/Request';

// 检核-获取终端名称配置
export function getSystemConf(params) {
  return request({
    url: '/speechskill/admin/check/system-conf',
    method: 'get',
    params
  });
}

// 检核-终端名称配置
export function saveSystemConf(data) {
  return request({
    url: '/speechskill/admin/check/system-conf',
    method: 'post',
    data
  });
}
