<template>
  <div class="ss-assessment-topic">
    <div class="row row-left">
      <div class="col col-left col-narrow">
        <SelectScriptCategory :default-selected-ids="defaultCategory" :disabled="readOnly" :individual="true"
                              @select="selectScriptCategory"></SelectScriptCategory>
      </div>
      <div class="col col-left ml-20">
        <a-input-search :disabled="readOnly" class="mxw-350 mnw-200" placeholder="请输入关键词" enter-button="搜 索"
                        v-model="filterTxt" @search="onSearch"/>
      </div>
    </div>

    <div class="row row-left">
      <div class="col col-left">
        <a-transfer :data-source="data"
                    :list-style="{width: '450px', height: '300px'}"
                    :operations="['选择', '取消']"
                    :target-keys="targetKeys"
                    :render="renderItem"
                    :disabled="readOnly"
                    @change="transferChange"></a-transfer>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col col-left">
        <a-button :disabled="readOnly" type="primary" @click="saveAndNext">保存并下一步</a-button>
        <a-button :disabled="readOnly" @click="save(false)">保存本页</a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  getSkillList,
} from '../../skill/api';
import {
  saveSkillGroupTopic,
} from '../api';

import SelectScriptCategory from '@components/speechSkill/SelectSkillCategory';

export default {
  name: 'Topic',
  components: { SelectScriptCategory },
  computed: {
    ...mapState({
      readOnly: state => state.Assessment.readOnly,
      assessment: state => state.Assessment.assessment,
    }),
  },
  watch: {
    'assessment.speech_skill_arr'(temp) {
      this.targetKeys = [];
      const list = [];
      for (let key in temp) {
        if (Object.prototype.hasOwnProperty.call(temp, key)) {
          list.push({
            key: key + '',
            title: temp[key],
          });
          this.targetKeys.push(key + '');
        }
      }
      this.getSkillList();
    },
  },
  data() {
    return {
      id: null,
      loading: false,
      filterTxt: '',

      data: [],
      targetKeys: [],
      selectedItems: [], // 临时保存已选中的列表项

      defaultCategory: [],
      selectedCate1: 0,
      selectedCate2: 0,
    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.$store.dispatch('Assessment/getAssessment', this.id);
    this.getSkillList();
  },
  destroyed() {
    this.$store.commit('Assessment/updateReadOnly', true);
    this.$store.commit('Assessment/updateAssessmentId', 0);
    this.$store.commit('Assessment/updateAssessment', {});
  },
  methods: {
    saveAndNext() {
      if (this.readOnly) {
        return;
      }
      this.save(() => {
        this.$router.replace({
          path: './pattern',
          query: {
            id: this.id,
          },
        });
      });
    },
    async save(next) {
      if (this.readOnly) {
        return;
      }
      if (this.loading) {
        return;
      }

      if (!this.targetKeys || !this.targetKeys.length) {
        this.$message.warning({ content: '请选择话术' });
        return;
      }
      const ids = this.targetKeys ? this.targetKeys.join(',') : '';

      this.loading = true;

      const data = await saveSkillGroupTopic({ id: this.id, skill_ids: ids }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
      next && next();
    },
    cancel() {
      this.$router.back();
    },

    selectScriptCategory(event) {
      this.selectedCate1 = event?.cate1?.id || null;
      this.selectedCate2 = event?.cate2?.id || null;
    },

    onSearch() {
      this.getSkillList();
    },

    async getSkillList() {
      if (this.loading) {
        return;
      }
      this.selectedItems = this.selectedItems.filter(i => this.targetKeys.includes(i.key));
      const temp = this.data.filter(i => this.targetKeys.includes(i.key) && this.selectedItems.every(item => item.key !== i.key));
      this.selectedItems.push(...temp);

      this.loading = true;
      const data = await getSkillList({
        status: 1,
        cate_1: this.selectedCate1,
        cate_2: this.selectedCate2,
        title: this.filterTxt,
        page: 1,
        per_page: 100,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      const list = [];
      result.forEach(i => list.push({
        key: i.id + '',
        title: i.title,
      }));
      list.push(...this.selectedItems.filter(i => list.every(res => res.key !== i.key)));
      this.data = list || [];
    },

    renderItem(item) {
      const customLabel = (
        <span>{item.title}</span>
      );

      return {
        label: customLabel, // for displayed item
        value: item.title, // for title and filter matching
      };
    },

    transferChange(targetKeys) {
      this.targetKeys = targetKeys;
    },
  },
};
</script>

<style scoped lang="scss">
.ss-assessment-topic {
  width: 100%;
}
</style>
