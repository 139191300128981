<template>
  <div class="flex flex-start flex-wrap ss-select ss-select-script-category">
    <template v-if="!individual">
      <span v-if="showLabel" class="flex-none mr-5 width-6em text-right">话术类别：</span>

      <a-cascader v-model="selectedIds"
                  :options="list"
                  :allow-clear="true"
                  :fieldNames="nameMap"
                  :disabled="disabled"
                  placeholder="请选择话术类别"
                  @change="select"/>
    </template>
    <template v-else>
      <div class="flex flex-start flex-wrap no-padding">
        <div class="flex flex-start mb-10 select-cate-1-col no-horizontal-padding">
          <span class="flex-none mr-5 width-6em text-right">话术类别：</span>
          <a-select v-model="selectedCate1Id" :allow-clear="true" :disabled="disabled"
                    class="select select-cate-1" placeholder="请选择话术类别"
                    @change="selectCate1">
            <a-select-option v-for="item of cate1List" :key="item.id" :value="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>

        <div class="flex flex-start flex-none mb-10 select-cate-2-col">
          <span class="flex-none mr-5 width-6em text-right">话术名称：</span>
          <a-select v-model="selectedCate2Id" :allow-clear="true" :disabled="disabled"
                    class="select select-cate-2" placeholder="请选择话术名称"
                    @change="selectCate2">
            <a-select-option v-for="item of cate2List" :key="item.id" :value="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'SelectSkillCategory',
  props: {
    showLabel: {type: Boolean, default: true},
    defaultSelectedIds: {type: Array, default: () => []},
    disabled: {type: Boolean, default: false},
    individual: {type: Boolean, default: false},
    showEmptyCateOne: {type: Boolean, default: false},
  },
  computed: {
    ...mapState({
      options: state => state.Common.scriptCategoryList,
    }),
    list() {
      return this.options.filter(i => this.showEmptyCateOne || i.children && i.children.length);
    },
    cate1List() {
      return this.options.filter(i => this.showEmptyCateOne || i.children && i.children.length);
    },
    cate2List() {
      const [cate1] = this.options.filter(o => o.id === this.selectedCate1Id);
      return cate1?.children || [];
    },
  },
  watch: {
    defaultSelectedIds(ids) {
      this.init(ids);
    },
  },
  data() {
    return {
      // 自定义 options 中 label name children 的字段
      nameMap: {label: 'title', value: 'id', children: 'children'},
      selectedIds: [],

      // individual
      selectedCate1Id: undefined,
      selectedCate2Id: undefined,
    }
  },
  created() {
    this.init(this.defaultSelectedIds);
  },
  methods: {
    init(ids) {
      if (!ids || !ids.length) {
        return;
      }
      this.selectedIds = ids;

      this.selectedCate1Id = ids[0] ? ids[0] : undefined;
      this.selectedCate2Id = ids[1] ? ids[1] : undefined;
    },

    select(value, ops) {
      const cate1 = ops && ops[0] ? {id: ops[0]?.id, title: ops[0]?.title} : null;
      const cate2 = ops && ops[1] ? {id: ops[1]?.id, title: ops[1]?.title} : null;
      this.$emit('select', {cate1, cate2});
    },

    selectCate1() {
      this.selectedCate2Id = undefined;
      this.sendSelect();
    },
    selectCate2() {
      this.sendSelect();
    },

    sendSelect() {
      const [cate1] = this.cate1List.filter(i => i.id === this.selectedCate1Id);
      const [cate2] = this.cate2List.filter(i => i.id === this.selectedCate2Id);
      this.$emit('select', {cate1, cate2});
    },
  },
}
</script>

<style scoped lang="scss">
.ss-select-script-category {
  .flex {
    width: 100%;
  }

  .select-cate-1-col {
    max-width: 400px;
  }

  .select-cate-2-col {
    max-width: 400px;
  }

  .select-cate-1 {
    min-width: 150px;
    max-width: 320px;
  }

  .select-cate-2 {
    min-width: 150px;
    max-width: 320px;
  }
}
</style>
