<template>
  <div class="ss-script-keyword">
    <div class="row">
      <div class="col col-left">
        <span>关键词加粗显示</span>
        <a-switch v-model="enhanceKeyword" :disabled="readOnly" class="switch"/>
      </div>
      <div class="col col-left">
        <a-button :disabled="readOnly" @click="showModal">添加关键词</a-button>
      </div>
    </div>

    <div class="row">
      <div class="col col-left">
        <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading"
                 row-key="id" class="table small-cell-table">
          <template slot="action" slot-scope="text, record">
            <a-button type="link" @click="editItem(record)">
              <span v-if="readOnly">查看</span>
              <span v-else>编辑</span>
            </a-button>
            <a-button v-if="!readOnly" type="link" @click="deleteItem(record)">删除</a-button>
          </template>

          <template slot="footer">
            <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                        class="pagination"
                        @change="onPageChange"
                        @showSizeChange="onSizeChange"></Pagination>
          </template>
        </a-table>
      </div>
    </div>
    <div class="row row-left">
      <div class="col col-left">
        <span class="msg">关键词总分必须为100分</span>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col col-left">
        <a-button :disabled="readOnly" type="primary" @click="saveAndNext">保存并下一步</a-button>
        <a-button :disabled="readOnly" @click="save(false)">保存本页</a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>


    <a-modal :title="modalTitle"
             :visible="createModalShown"
             :confirm-loading="confirmLoading"
             @ok="confirmModal"
             @cancel="closeModal">
      <a-form>
        <a-form-item :label="modalKeywordLabel">
          <a-textarea v-model="modalKeyword" :disabled="readOnly" :placeholder="modalKeywordLabel"></a-textarea>
        </a-form-item>
        <a-form-item :label="modalScoreLabel">
          <a-input v-model="modalScore" :disabled="readOnly" :placeholder="modalScoreLabel"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { getKeywords, saveKeywords, deleteKeywords, boldKeyword } from '../api/keyword';
import Common from '@/utils/Common';

export default {
  name: 'Keyword',
  computed: {
    ...mapState({
      readOnly: state => state.Script.readOnly,
      detail: state => state.Script.script,
    }),
    modalTitle() {
      return (this.readOnly ? '查看' : '添加') + '关键词';
    },
    modalKeywordLabel() {
      return (this.readOnly ? '' : '请输入') + '关键词';
    },
    modalScoreLabel() {
      return (this.readOnly ? '' : '请输入') + '分值';
    },
  },
  watch: {
    'detail.is_keyword_bold'(isBold) {
      this.enhanceKeyword = !!isBold;
    },
  },
  data() {
    return {
      enhanceKeyword: true,
      columns: [
        { align: 'center', dataIndex: 'id', key: 'id', title: 'ID' },
        { align: 'center', dataIndex: 'title', key: 'title', title: '关键词' },
        { align: 'center', dataIndex: 'score', key: 'score', title: '分数' },
        {
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          width: '180px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      filterTxt: '', // 搜索字符串
      data: [], // 话术分类列表
      loading: false,
      id: null,

      keywordId: 0,
      createModalShown: false,
      confirmLoading: false,
      modalKeyword: '',
      modalScore: 0,

      pagination: {
        current: 1,
        defaultCurrent: 1,
        defaultPageSize: 10,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total} 条`,
        total: 0,
      },

    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.getKeyWords(1, this.pagination.pageSize);
    this.$store.dispatch('Script/getScript', this.id);
  },
  destroyed() {
    this.$store.commit('Script/updateReadOnly', true);
    this.$store.commit('Script/updateScriptId', 0);
    this.$store.commit('Script/updateScript', {});
  },
  methods: {
    saveAndNext() {
      if (this.readOnly) {
        return;
      }
      this.save(() => {
        this.$router.replace({
          path: './practice',
          query: {
            id: this.id,
          },
        });
      });
    },
    async save(next) {
      if (this.readOnly) {
        return;
      }
      await this.boldKeyword();
      if (!next) {
        return this.$message.success({ content: '保存成功' });
      }
      next && next();
    },
    cancel() {
      this.$router.back();
    },

    async getKeyWords(page, pageSize) {
      this.loading = true;
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      const title = this.filterTxt;

      const data = await getKeywords({
        speech_skill_id: this.id,
        title,
        page: page,
        per_page: pageSize,
      }).finally(() => this.loading = false);

      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    editItem(item) {
      this.keywordId = item.id;
      this.modalKeyword = item.title || '';
      this.modalScore = item.score || 0;
      this.showModal();
    },
    deleteItem(item) {
      if (this.readOnly) {
        return;
      }
      this.$confirm({
        content: () => <div>确认删除该关键词？</div>,
        onOk: () => {
          this.confirmDelete(item);
        },
      });
    },
    async confirmDelete(item) {
      if (this.readOnly) {
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await deleteKeywords({ id: item.id }).finally(() => this.loading = false);
      if (!data || data._status_code !== 204) {
        return this.$message.error({ content: data?.message || '删除失败' });
      }
      this.$message.success({ content: data?.message || '删除成功' });
      this.data = this.data && this.data.length ? this.data.filter(i => i.id !== item.id) : [];

      const cur = this.pagination.current;
      const page = !this.data?.length ? (cur > 1 ? cur - 1 : 1) : cur;
      this.getKeyWords(page, this.pagination.pageSize);
    },

    importKeyword() {
    },

    async boldKeyword() {
      if (this.readOnly) {
        return;
      }
      this.loading = true;
      await boldKeyword({
        id: this.id,
        value: this.enhanceKeyword ? 1 : 0,
      }).finally(() => this.loading = false);
    },


    async saveKeyword() {
      if (this.readOnly) {
        return;
      }
      if (!this.modalKeyword) {
        this.$message.warning({ content: '请输入关键词' });
        return Promise.reject('NoKeyword');
      }
      this.confirmLoading = true;
      const data = await saveKeywords({
        id: this.keywordId,
        speech_skill_id: this.id,
        title: this.modalKeyword,
        score: this.modalScore,
      }).finally(() => this.confirmLoading = false);

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.getKeyWords(1, this.pagination.pageSize);
    },
    async confirmModal() {
      if (!this.readOnly) {
        await this.saveKeyword();
      }
      this.closeModal();
    },
    showModal() {
      this.createModalShown = true;
    },
    closeModal() {
      this.createModalShown = false;
      this.modalKeyword = '';
      this.modalScore = 0;
      this.keywordId = 0;
    },
  },
};
</script>

<style scoped lang="scss">
.ss-script-keyword {
  width: 100%;

  .row {
    margin: 20px 0;
    padding: 0;

    .col {
      width: 100%;
      padding: 0;
    }
  }

  .switch {
    margin-left: 10px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .msg {
    font-size: 12px;
    color: $light04;
  }
}
</style>

