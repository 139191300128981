<template>
  <div class="ss-select-script-status">
    <span v-if="showLabel">话术状态：</span>

    <a-select v-model="selectedId" placeholder="请选择话术状态" class="select" @change="select">
      <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
    </a-select>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'SelectSkillStatus',
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapState({
      options: state => state.Common.scriptStatusList,
    }),
  },
  data() {
    return {
      selectedId: 0,
    }
  },
  methods: {
    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      const idx = this.options.findIndex(op => op.id === this.selectedId);
      if (idx < 0) {
        return;
      }

      this.$emit('select', this.options[idx]);
    },
  }
}
</script>

<style scoped lang="scss">
.ss-select-script-status {
  .select {
    width: 120px;
  }
}
</style>
