<template>
  <div class="ph-20">
    <div class="flex">
      <span class="flex-none">首页入口名称：</span>
      <a-input v-model="entryName" placeholder="请输入名称"/>
    </div>
    <div class="mt-5 mb-10 input-hint">限4个字</div>

    <div class="flex">
      <span class="flex-none">检核列表名称：</span>
      <a-input v-model="listName" placeholder="请输入名称"/>
    </div>
    <div class="mt-5 mb-10 input-hint">限4个字</div>

    <div class="flex mt-10">
      <a-button type="primary" @click="saveSystemConf">提交</a-button>
    </div>

  </div>
</template>

<script>
import {
  getSystemConf,
  saveSystemConf,
} from './api/tagEnd';

export default {
  name: 'TagEndConfig',
  data() {
    return {
      entryName: '',
      listName: '',
      loading: false,
    };
  },
  created() {
    this.getSystemConf();
  },
  methods: {
    async getSystemConf() {
      this.loading = true;
      const data = await getSystemConf().finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }

      const result = data?.data || [];
      this.entryName = result[0]?.data_json?.name || '';
      this.listName = result[1]?.data_json?.name || '';
    },

    async saveSystemConf() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      const data = await saveSystemConf({
        home_speech_skill_name: this.entryName,
        speech_skill_name: this.listName,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.warning({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
    },
  },
};
</script>

<style scoped lang="scss">
.input-hint {
  padding-left: 105px;
  font-size: 12px;
  color: $light02;
}
</style>
