<template>
  <div class="ss-script-keyword">
    <div class="row">
      <div class="col col-left col-top">
        <span>话术文案：</span>
        <a-textarea v-model="content" ref="scriptContent" placeholder="请输入话术文案" class="script-content"
                    :disabled="readOnly" :auto-size="{ minRows: 4, maxRows: 24 }"
                    @keypress.prevent="stopInput" @mouseup.stop="stopMouseUp"></a-textarea>
        <div class="actions">
          <a-button class="btn" :disabled="readOnly" @mouseup.stop="stopMouseUp" @click="setQuiz">设为填空内容</a-button>
          <br/>
          <a-button class="btn" :disabled="readOnly" @mouseup.stop="stopMouseUp" @click="cancelQuiz">取消设置</a-button>
          <p>设置填空：拉选文字后，点击设为填空内容</p>
          <p>取消填空：点击选中已设内容后，点击取消设置</p>
          <hr>
          <a-button class="btn" :disabled="readOnly" @mouseup.stop="stopMouseUp" @click="setKeywordsToQuiz">
            将关键词设为填空内容
          </a-button>
        </div>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col col-left">
        <a-button :disabled="readOnly" type="primary" @click="saveAndNext">保存并下一步</a-button>
        <a-button :disabled="readOnly" @click="save(false)">保存本页</a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { saveSkillPractice } from '../api';

export default {
  name: 'Practice',
  computed: {
    ...mapState({
      readOnly: state => state.Script.readOnly,
      script: state => state.Script.script,
    }),
  },
  watch: {
    'script.content'(content) {
      this.content = content || '';
    },
    'script.keyword_title'(keywords) {
      this.keywords = keywords || [];
    },
  },
  data() {
    return {
      filterTxt: '', // 搜索字符串
      data: [], // 话术分类列表
      loading: false,

      content: '',
      keywords: [],

      selection: [],
    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.$store.dispatch('Script/getScript', this.id);
  },
  mounted() {
    this.addListener();
  },
  destroyed() {
    this.removeListener();
    this.$store.commit('Script/updateReadOnly', true);
    this.$store.commit('Script/updateScriptId', 0);
    this.$store.commit('Script/updateScript', {});
  },
  methods: {
    saveAndNext() {
      if (this.readOnly) {
        return;
      }
      this.save(() => {
        this.$router.replace({
          path: './verification',
          query: {
            id: this.id,
          },
        });
      });
    },
    async save(next) {
      if (this.readOnly) {
        return;
      }
      await this.savePractice();
      next && next();
    },
    cancel() {
      this.$router.back();
    },

    async savePractice() {
      if (this.readOnly) {
        return;
      }
      this.loading = true;
      const data = await saveSkillPractice({ id: this.id, content: this.content }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.warning({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
    },

    stopInput() {
      console.log('oh stop input');
      /**
       * 禁止用户输入
       */
    },

    stopMouseUp() {
      /**
       * 阻止 mouseup 事件冒泡，防止 selection 数组被清空
       */
    },

    addListener() {
      const content = this.$refs.scriptContent?.$el;
      if (!content) {
        return;
      }
      content.addEventListener('mouseup', this.getSelection, false);
      content.addEventListener('keyup', this.getSelection, false);
      document.addEventListener('mouseup', this.clearSelection, false);
    },
    removeListener() {
      const content = this.$refs.scriptContent?.$el;
      if (!content) {
        return;
      }
      content.removeEventListener('mouseup', this.getSelection);
      content.removeEventListener('keyup', this.getSelection);
      document.removeEventListener('mouseup', this.clearSelection);
    },

    clearSelection() {
      this.selection = [];
    },

    getSelection() {
      const content = this.$refs.scriptContent?.$el;
      if (!content) {
        return;
      }

      const start = content.selectionStart;
      const end = content.selectionEnd;

      this.selection = [Math.min(start, end), Math.max(start, end)];
    },

    setQuiz() {
      if (this.readOnly) {
        return;
      }
      if (!this.content) {
        this.$message.warning({ content: '请输入话术文案' });
        return;
      }

      if (!this.selection || this.selection.length !== 2) {
        this.$message.warning({ content: '请选中要设置为填空的内容' });
        return;
      }

      const length = this.content.length;
      const start = this.selection[0] > length ? length : this.selection[0];
      const end = this.selection[1] > length ? length : this.selection[1];

      const quiz = this.content.slice(start, end);
      const beforeQuiz = this.content.slice(0, start);
      const afterQuiz = this.content.slice(end);

      // 未选中任何内容
      if (start === end) {
        this.$notice({ content: () => <div>请选中要设置为填空的内容</div> });
        this.clearSelection();
        return;
      }

      // 选中内容为 [ 或 [[ 或 ]] 或 ]
      if (quiz === '[' || quiz === '[[' || quiz === ']]' || quiz === ']') {
        this.$notice({ content: () => <div>请选中要设置为填空的内容</div> });
        this.clearSelection();
        return;
      }

      // 选中内容包含 [[ 或 ]]
      if (quiz.indexOf('[[') > -1 || quiz.indexOf(']]') > -1) {
        this.$notice({ content: () => <div>选中内容包含了其它填空内容</div> });
        this.clearSelection();
        return;
      }

      // 选中内容在 [[ 和 ]] 之间
      const lastStart = beforeQuiz.lastIndexOf('[[');
      const lastEnd = beforeQuiz.lastIndexOf(']]');
      const firstStart = afterQuiz.indexOf('[[');
      const firstEnd = afterQuiz.indexOf(']]');
      if (lastStart > lastEnd && lastEnd >= -1 && firstStart > firstEnd && firstEnd >= -1) {
        this.$notice({ content: () => <div>选中内容被包含在其它填空内容中，请重新选择</div> });
        this.clearSelection();
        return;
      }

      this.content = beforeQuiz + '[[' + quiz + ']]' + afterQuiz;
      this.$message.info({ content: '设置成功' });
      this.clearSelection();
    },
    cancelQuiz() {
      if (this.readOnly) {
        return;
      }
      if (!this.content) {
        this.$message.warning({ content: '请输入话术文案' });
        return;
      }

      if (!this.selection || this.selection.length !== 2) {
        this.$message.warning({ content: '请选中要取消填空的内容' });
        return;
      }

      const length = this.content.length;
      const start = this.selection[0] > length ? length : this.selection[0];
      const end = this.selection[1] > length ? length : this.selection[1];

      const selected = this.content.slice(start, end);
      const beforeSelected = this.content.slice(0, start);
      const afterSelected = this.content.slice(end);

      const lastStart = beforeSelected.lastIndexOf('[[');
      const lastEnd = beforeSelected.lastIndexOf(']]');
      const firstStart = afterSelected.indexOf('[[');
      const firstEnd = afterSelected.indexOf(']]');

      let content = '';
      let beforeContent = '';
      let afterContent = '';
      if (lastStart > lastEnd && lastEnd >= -1) {
        beforeContent = beforeSelected.slice(0, lastStart) + beforeSelected.slice(lastStart + 2);
      } else {
        beforeContent = beforeSelected;
      }
      if ((firstStart === -1 || firstStart > firstEnd) && firstEnd > -1) {
        afterContent = afterSelected.slice(0, firstEnd) + afterSelected.slice(firstEnd + 2);
      } else {
        afterContent = afterSelected;
      }
      content = selected.replace(/\[\[/g, '');
      content = content.replace(/]]/g, '');
      content = beforeContent + content + afterContent;

      const msg = content === this.content ? '未取消任何填空' : '取消成功';
      this.$message.info({ content: msg });

      this.content = content;
      this.clearSelection();
    },

    setKeywordsToQuiz() {
      if (this.readOnly) {
        return;
      }
      if (!this.content) {
        this.$message.info({ content: '话术文案为空' });
        return;
      }
      this.clearSelection();
      let scriptContent = this.content;

      this.keywords.forEach(keyword => {
        const parsedKeyword = this.parseKeyword(keyword);
        const reg = new RegExp(parsedKeyword, 'g');
        scriptContent = scriptContent.replace(reg, `[[${keyword}]]`);

        // 关键词：互换位置

        // 1. [[xxx互换位置xxx]]
        // 2. 互换[[位置xxx]]    [[xxx互换]]位置

      });

      this.content = scriptContent;
    },

    parseKeyword(keyword) {
      const regMark = ['+', '-', '.', '*', '\\', '^', '$', '[', ']', '(', ')'];
      let parsed = keyword;
      regMark.forEach((i, idx) => {
        if (i === '\\' && idx < regMark.length - 1 && regMark.includes(regMark[idx + 1])) {
          return;
        }
        parsed = parsed.replace(new RegExp('\\' + i, 'g'), '\\' + i);
      });
      return parsed;
    },

    // isValid(content) {
    //   const reg = /.*\[\[.*]].*/g;
    // },
  },
};
</script>

<style scoped lang="scss">
.ss-script-keyword {
  width: 100%;

  .row {
    margin: 20px 0;
    padding: 0;

    .col {
      width: 100%;
      padding: 0;
    }
  }

  .mt-30 {
    margin-top: 30px;
  }

  .actions {
    width: 300px;
    margin-left: 15px;

    .btn {
      margin-bottom: 15px;
    }
  }
}
</style>

